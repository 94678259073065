import React, { useState, useRef } from 'react';
import gsap from 'gsap';
import useOutside from 'react-useoutside-handler';
import { useIntl } from 'gatsby-plugin-intl';
import {
  State,
  Request,
  RequestBlank,
  RequestButton,
  RequestIcon,
  RequestModal,
  RequestModalBlank,
  RequestModalIcon,
  RequestTitle,
} from './styled';

const Aside = () => {
  const intl = useIntl();
  const [stateSupport, setStateSupport] = useState(false);
  const refSupportButton = useRef(null);
  const refSupportModal = useRef(null);

  const handleSupportButton = () => {
    if (stateSupport === false) {
      setStateSupport(true);

      setTimeout(() => {
        gsap.to(refSupportButton.current, {
          duration: 0.25,
          x: '100%',
          onComplete() {
            gsap.to(refSupportModal.current, {
              duration: 0.25,
              x: '0%',
            });
          },
        });
      }, 250);
    }
  };

  useOutside(refSupportModal, () => {
    if (stateSupport === true) {
      setTimeout(() => {
        gsap.to(refSupportModal.current, {
          duration: 0.25,
          x: '100%',
          onComplete() {
            gsap.to(refSupportButton.current, {
              duration: 0.25,
              x: '0%',
            });

            setStateSupport(false);
          },
        });
      }, 500);
    }
  });

  return (
    <State>
      <Request partner>
        <RequestBlank href="/affiliate" target="_blank">
          <RequestIcon icon="partner" partner />

          <RequestTitle partner>
            {intl.formatMessage({ id: 'aside.request.partner' })}
          </RequestTitle>
        </RequestBlank>
      </Request>

      <Request onClick={handleSupportButton} support>
        <RequestButton ref={refSupportButton} type="button">
          <RequestIcon icon="support" support />

          <RequestTitle support>
            {intl.formatMessage({ id: 'aside.request.support' })}
          </RequestTitle>
        </RequestButton>

        <RequestModal ref={refSupportModal}>
          <RequestModalBlank
            aria-label="Line ID"
            href="https://lin.ee/Q7kNgbW"
            target="_blank"
            rel="noopener noreferrer"
            line
          >
            <RequestModalIcon icon="line" modal />
          </RequestModalBlank>

          {/*
            <RequestModalBlank aria-label="Telephone" href="tel:0960724119">
              <RequestModalIcon icon="tel" modal />
            </RequestModalBlank>
          */}
        </RequestModal>
      </Request>
    </State>
  );
};

export default Aside;
