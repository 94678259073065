// import { calcRem } from 'utils';

const theme = {
  palette: {
    black: '#000000',
    white: '#ffffff',
    dark: '#131111',
    base: '#c0c0c0',
    theme: '#CC9933',
    themeLight: '#f0d67e',
    section: '#181818',
    border: '#1b1e21',
    goldPlate: 'linear-gradient(180deg, rgb(240, 214, 126), rgb(240, 214, 126), rgb(143, 99, 20))',
    silverPlate: 'linear-gradient(180deg, rgb(74, 74, 74), rgb(158, 158, 158), rgb(74, 74, 74))',
    textShadow: '1px 1px 0 rgba(255,255,255,.5), -1px -1px 0 rgba(255,255,255,.5), 1px -1px 0 rgba(255,255,255,.5), -1px 1px 0 rgba(255,255,255,.5)',
  },
  scale: {
  },
  typography: {
  },
};

export default theme;
