import styled from 'styled-components';
import { calcRem, Iconsvg } from 'utils';

const State = styled.div`
  position: fixed;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  z-index: 99;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const Request = styled.div`
  width: ${calcRem(48)};
  height: ${calcRem(174)};
  position: relative;
  border-top-left-radius: ${calcRem(8)};
  border-bottom-left-radius: ${calcRem(8)};
  overflow: hidden;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }

  ${(props) => props.partner && `
    margin: 0 0 ${calcRem(8)};
  `};

  ${(props) => props.support && `
  `};
`;

const RequestButton = styled.button`
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  background: ${(props) => props.theme.palette.silverPlate};
  border: none;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }

  ${(props) => props.partner && `
  `};

  ${(props) => props.support && `
  `};
`;

const RequestBlank = styled.a`
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  background: ${(props) => props.theme.palette.goldPlate};

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }

  ${(props) => props.partner && `
  `};

  ${(props) => props.support && `
  `};
`;

const RequestIcon = styled(Iconsvg)`
  width: ${calcRem(42)};
  height: ${calcRem(42)};
  position: absolute;
  top: ${calcRem(8)};
  left: 50%;
  transform: translate(-50%, 0);

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }

  ${(props) => props.partner && `
    fill: #000000;
  `};

  ${(props) => props.support && `
    fill: #000000;
  `};
`;

const RequestTitle = styled.span`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, ${calcRem(8)}) rotate(90deg);
  line-height: 0.75;
  font-size: ${calcRem(21)};
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }

  ${(props) => props.partner && `
    color: #000000;
  `};

  ${(props) => props.support && `
    color: #000000;
  `};
`;

const RequestModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(100%, 0);

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const RequestModalBlank = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${calcRem(48)};
  height: ${calcRem(48)};
  position: relative;
  background-color: #000000;
  border: 1px solid ${(props) => props.theme.palette.border};

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }

  ${(props) => props.line && `
    margin: 0 0 ${calcRem(8)};
  `};

  ${(props) => props.tel && `
  `};
`;

const RequestModalIcon = styled(Iconsvg)`
  width: ${calcRem(42)};
  height: ${calcRem(42)};
  transition: all 0.25s ease;
  fill: ${(props) => props.theme.palette.base};

  &:hover{
    fill: ${(props) => props.theme.palette.theme};
  }
`;

export {
  State,
  Request,
  RequestBlank,
  RequestButton,
  RequestIcon,
  RequestModal,
  RequestModalBlank,
  RequestModalIcon,
  RequestTitle,
};
