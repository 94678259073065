import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'components';
import { ImageResponsive } from 'utils';
import {
  State,
  Providers,
  ProviderApp,
  ProviderCover,
  ProviderTitle,
} from './modal-styled';

const Modal = (props) => {
  const { type, render } = props;

  const providersCasinos = {
    id_001: {
      title: 'Evolution Gaming',
      thumbnail: 'app-casino--evolutiongaming.png',
    },
    id_002: {
      title: 'G Club Casino',
      thumbnail: 'app-casino--gclubcasino.png',
    },
    id_003: {
      title: 'SA Gaming',
      thumbnail: 'app-casino--sagaming.png',
    },
    id_004: {
      title: 'Sexy Barcarat',
      thumbnail: 'app-casino--sexybarcarat.png',
    },
    id_005: {
      title: 'WM Casino',
      thumbnail: 'app-casino--wmcasino.png',
    },
  };

  const providersEsports = {
    id_001: {
      title: 'EZ Game',
      thumbnail: 'winner-provider--ezgame.png',
    },
  };

  const providersSports = {
    id_001: {
      title: 'Nova 88',
      thumbnail: 'app-sport--nova88.png',
    },
    id_002: {
      title: 'SBOBET',
      thumbnail: 'app-sport--sbobet.png',
    },
    id_003: {
      title: 'TS 911',
      thumbnail: 'app-sport--ts911.png',
    },
    id_004: {
      title: 'UFA Bet',
      thumbnail: 'app-sport--ufabet.png',
    },
    id_005: {
      title: 'M8BET',
      thumbnail: 'provider-sport--m8bet.png',
    },
  };

  const providersSlots = {
    id_001: {
      title: '918 Kiss',
      thumbnail: 'app-slot--918kiss.png',
    },
    id_002: {
      title: 'Joker Gaming',
      thumbnail: 'app-slot--joker.png',
    },
    id_003: {
      title: 'Mega 888',
      thumbnail: 'app-slot--mega888.png',
    },
    id_004: {
      title: 'Pussy 888',
      thumbnail: 'app-slot--pussy888.png',
    },
    id_005: {
      title: 'XE 88',
      thumbnail: 'app-slot--xe88.png',
    },
    id_006: {
      title: 'Slot Citi',
      thumbnail: 'app-slot--slotciti.png',
    },
    id_007: {
      title: '3WIN8',
      thumbnail: 'provider-casino--3win8.png',
    },
    id_008: {
      title: 'Gaming Soft',
      thumbnail: 'provider-casino--gamingsoft.png',
    },
    id_009: {
      title: 'Pragmatic Play',
      thumbnail: 'provider-casino--pramaticplay.png',
    },

    id_009po: {
      title: 'Playngo',
      thumbnail: 'app-slot--playngo.png',
    },
    id_009io: {
      title: 'Sample Play',
      thumbnail: 'app-slot--sampleplay.png',
    },
    id_009oo: {
      title: 'Slot XO',
      thumbnail: 'app-slot--slotxo.png',
    },

    id_010: {
      title: 'CQ9 Gaming',
      thumbnail: 'cq9-gaming_480.png',
    },
    id_011: {
      title: 'Habannero',
      thumbnail: 'harbanero.png',
    },
    id_012: {
      title: 'Micro Gaming',
      thumbnail: 'microgaming_480.png',
    },
    id_013: {
      title: 'PG Soft',
      thumbnail: 'pgpocketsoft_480.png',
    },
    id_014: {
      title: 'Spade Gaming',
      thumbnail: 'spadegaming.png',
    },
  };

  const providersCasinoList = Object.keys(providersCasinos).map((key) => (
    <ProviderApp
      key={key}
      href="https://app.alltopasia.com/login/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <ProviderCover>
        <ImageResponsive
          source={providersCasinos[key].thumbnail}
          alt="ALLTOP ASIA Casino Online"
          fadeIn
        />
      </ProviderCover>

      <ProviderTitle>{providersCasinos[key].title}</ProviderTitle>
    </ProviderApp>
  ));

  const providersEsportsList = Object.keys(providersEsports).map((key) => (
    <ProviderApp
      key={key}
      href="https://app.alltopasia.com/login/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <ProviderCover>
        <ImageResponsive
          source={providersEsports[key].thumbnail}
          alt="ALLTOP ASIA E-sports Betting Online"
          fadeIn
        />
      </ProviderCover>

      <ProviderTitle>{providersEsports[key].title}</ProviderTitle>
    </ProviderApp>
  ));

  const providersSportsList = Object.keys(providersSports).map((key) => (
    <ProviderApp
      key={key}
      href="https://app.alltopasia.com/login/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <ProviderCover>
        <ImageResponsive
          source={providersSports[key].thumbnail}
          alt="ALLTOP ASIA Sports Betting Online"
          fadeIn
        />
      </ProviderCover>

      <ProviderTitle>{providersSports[key].title}</ProviderTitle>
    </ProviderApp>
  ));

  const providersSlotsList = Object.keys(providersSlots).map((key) => (
    <ProviderApp
      key={key}
      href="https://app.alltopasia.com/login/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <ProviderCover>
        <ImageResponsive
          source={providersSlots[key].thumbnail}
          alt="ALLTOP ASIA Slots Online"
          fadeIn
        />
      </ProviderCover>

      <ProviderTitle>{providersSlots[key].title}</ProviderTitle>
    </ProviderApp>
  ));

  if (render === true) {
    return (
      <State>
        <Container>
          <Providers>
            {type === 'casino' && providersCasinoList}
            {type === 'esport' && providersEsportsList}
            {type === 'slot' && providersSlotsList}
            {type === 'sport' && providersSportsList}
          </Providers>
        </Container>
      </State>
    );
  }

  return null;
};

Modal.propTypes = {
  type: PropTypes.string.isRequired,
  render: PropTypes.bool.isRequired,
};

export default Modal;
