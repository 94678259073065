import { createStore, applyMiddleware } from 'redux';
// import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/es/storage/session';

// Imports: Redux
import rootReducer from 'reducers';

// Middleware: Redux Persist Config
const persistConfig = {
  // Root
  key: 'root',

  // Storage Method (React Native)
  storage: sessionStorage,

  // Whitelist (Save Specific Reducers)
  whitelist: [
    'userInterface',
  ],

  // Blacklist (Don't Save Specific Reducers)
  blacklist: [
    'userInterfacWhitelist',
  ],
};

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Redux: Store
const store = createStore(
  persistedReducer,
  applyMiddleware(
    // createLogger(),
  ),
);

// Middleware: Redux Persist Persister
const persistor = persistStore(store);

// Exports
export {
  store,
  persistor,
};
