import React from 'react';
import {
  Container,
} from 'components';
import { State, Inner, Hilight, Newline } from './styled';

const Notification = () => (
  <State>
    <Container>
      <Inner>
        ประกาศ: เนื่องจาก Line Official
        {' '}
        <Hilight>@alltop</Hilight>
        {' '}
        มีปัญหา
        {' '}

        <Newline>
          กรุณาติดต่อที่
          {' '}
          <a href="https://lin.ee/Q7kNgbW" target="_blank" rel="noopener noreferrer">@alltopfun</a>
          {' '}
          หรือ Telegram
          {' '}
          <a href="https://t.me/+669-4301-3797">+669-4301-3797</a>
          {/* {' '}
          หรือ Telegram
          {' '}
          <a href="tel:0960724119">09-6-072-4119</a> */}
        </Newline>
      </Inner>
    </Container>
  </State>
);

export default Notification;
