import styled, { css } from 'styled-components';
import { Link } from 'gatsby-plugin-intl';
import { calcRem } from 'utils';

const State = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${calcRem(90)};
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #000000;
  border-top: 1px solid #1b1e21;
  z-index: 999;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
    display: none;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) {
    display: none;
  }
`;

const MenuLink = styled(Link)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }
`;

const MenuBlank = styled.a`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }
`;

const MenuIcon = styled.div`
  width: ${calcRem(42)};
  height: ${calcRem(42)};
  margin: 0 0 ${calcRem(4)};
  fill: #ffffff;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  ${(props) => props.center && css`
    fill: #d9b768;
  `}
`;

const MenuTitle = styled.span`
  display: block;
  color: #ffffff;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  ${(props) => props.center && css`
    color: #d9b768;
  `}
`;

export {
  State,
  MenuBlank,
  MenuLink,
  MenuIcon,
  MenuTitle,
};
