import React, { useRef } from 'react';
import gsap from 'gsap';
import useOutside from 'react-useoutside-handler';
import useWindowDimensions from 'hooks';
import { ImageResponsive } from 'utils';
import { changeLocale, IntlContextConsumer, useIntl } from 'gatsby-plugin-intl';
import {
  State,
  Contact,
  ContactBlank,
  ContactDesc,
  ContactHeading,
  Copyright,
  Info,
  InfoDesc,
  InfoHeading,
  Note,
  Providers,
  ProvidersBlank,
  Register,
  RegisterButton,
  RegisterTitle,
  Sitemap,
  SitemapColumn,
  SitemapHeading,
  SitemapMenu,
  SitemapMenuBlank,
  SitemapMenuButton,
  SitemapMenuItem,
  SitemapMenuLink,
  SitemapMenuRouter,
  SitemapMobile,
  SitemapMobileAccordian,
  SitemapMobileButton,
  SitemapMobileMenu,
  SitemapMobileMenuBlank,
  SitemapMobileMenuButton,
  SitemapMobileMenuItem,
  SitemapMobileMenuLink,
  SitemapMobileMenuRouter,
  SitemapState,
} from './styled';

const languageName = {
  en: 'English',
  th: 'ภาษาไทย',
};

const Footer = () => {
  const intl = useIntl();
  const refSitemap = useRef(null);
  const refCasinosMenu = useRef(null);
  const refEvenetsMenu = useRef(null);
  const refBusinessMenu = useRef(null);
  const refSupportsMenu = useRef(null);
  const refLanguagesMenu = useRef(null);
  const { width } = useWindowDimensions();

  const handleCasinosButton = () => {
    gsap.to(refCasinosMenu.current, {
      duration: 0.25,
      height: 'auto',
      onComplete() {},
    });

    gsap.to(refEvenetsMenu.current, {
      duration: 0.25,
      height: 0,

      onComplete() {},
    });

    gsap.to(refBusinessMenu.current, {
      duration: 0.25,
      height: 0,

      onComplete() {},
    });

    gsap.to(refSupportsMenu.current, {
      duration: 0.25,
      height: 0,

      onComplete() {},
    });

    gsap.to(refLanguagesMenu.current, {
      duration: 0.25,
      height: 0,

      onComplete() {},
    });
  };

  const handleEventsButton = () => {
    gsap.to(refEvenetsMenu.current, {
      duration: 0.25,
      height: 'auto',

      onComplete() {},
    });

    gsap.to(refCasinosMenu.current, {
      duration: 0.25,
      height: 0,

      onComplete() {},
    });

    gsap.to(refBusinessMenu.current, {
      duration: 0.25,
      height: 0,

      onComplete() {},
    });

    gsap.to(refSupportsMenu.current, {
      duration: 0.25,
      height: 0,

      onComplete() {},
    });

    gsap.to(refLanguagesMenu.current, {
      duration: 0.25,
      height: 0,

      onComplete() {},
    });
  };

  const handleBusinessButton = () => {
    gsap.to(refBusinessMenu.current, {
      duration: 0.25,
      height: 'auto',

      onComplete() {},
    });

    gsap.to(refCasinosMenu.current, {
      duration: 0.25,
      height: 0,

      onComplete() {},
    });

    gsap.to(refEvenetsMenu.current, {
      duration: 0.25,
      height: 0,

      onComplete() {},
    });

    gsap.to(refSupportsMenu.current, {
      duration: 0.25,
      height: 0,

      onComplete() {},
    });

    gsap.to(refLanguagesMenu.current, {
      duration: 0.25,
      height: 0,

      onComplete() {},
    });
  };

  const handleSupportsButton = () => {
    gsap.to(refSupportsMenu.current, {
      duration: 0.25,
      height: 'auto',

      onComplete() {},
    });

    gsap.to(refCasinosMenu.current, {
      duration: 0.25,
      height: 0,

      onComplete() {},
    });

    gsap.to(refEvenetsMenu.current, {
      duration: 0.25,
      height: 0,

      onComplete() {},
    });

    gsap.to(refBusinessMenu.current, {
      duration: 0.25,
      height: 0,

      onComplete() {},
    });

    gsap.to(refLanguagesMenu.current, {
      duration: 0.25,
      height: 0,

      onComplete() {},
    });
  };

  const handleLanguagesButton = () => {
    gsap.to(refLanguagesMenu.current, {
      duration: 0.25,
      height: 'auto',
      onComplete() {},
    });

    gsap.to(refCasinosMenu.current, {
      duration: 0.25,
      height: 0,
      onComplete() {},
    });

    gsap.to(refEvenetsMenu.current, {
      duration: 0.25,
      height: 0,
      onComplete() {},
    });

    gsap.to(refBusinessMenu.current, {
      duration: 0.25,
      height: 0,
      onComplete() {},
    });

    gsap.to(refSupportsMenu.current, {
      duration: 0.25,
      height: 0,
      onComplete() {},
    });
  };

  useOutside(refSitemap, () => {
    gsap.to(refLanguagesMenu.current, {
      duration: 0.25,
      height: 0,
    });

    gsap.to(refCasinosMenu.current, {
      duration: 0.25,
      height: 0,
    });

    gsap.to(refEvenetsMenu.current, {
      duration: 0.25,
      height: 0,
    });

    gsap.to(refBusinessMenu.current, {
      duration: 0.25,
      height: 0,
    });

    gsap.to(refSupportsMenu.current, {
      duration: 0.25,
      height: 0,
    });
  });

  const providers = [
    'provider-casino--evolutiongaming.png',
    'provider-casino--gclubcasino.png',
    'provider-casino--sa.png',
    'provider-casino--sexybarcarat.png',
    'provider-casino--wm.png',
    'provider-esport--ezgame.png',
    'provider-slot--918kiss.png',
    'provider-slot--joker.png',
    'provider-slot--mega888.png',
    'provider-slot--pussy888.png',
    'provider-slot--xe88.png',
    // 'provider-sport--casa98.png',
    'provider-sport--sbobet.png',
    'provider-sport--ts911.png',
    'provider-sport--ufabet.png',
  ];

  const providerLists = providers.map((provider) => (
    <div key={provider.toString()}>
      <ProvidersBlank
        href="https://app.alltopasia.com/login/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ImageResponsive
          source={provider}
          alt="ALLTOP ASIA Casino Online"
          fadeIn
        />
      </ProvidersBlank>
    </div>
  ));

  return (
    <State>
      <Register>
        <RegisterTitle>
          {intl.formatMessage({ id: 'footer.register.desc' })}
        </RegisterTitle>

        <RegisterButton
          href="https://app.alltopasia.com/register/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {intl.formatMessage({ id: 'footer.register.button' })}
        </RegisterButton>
      </Register>

      {width > 1400 && (
        <Providers
          arrows={false}
          arrowsBlock={false}
          autoplaySpeed={10000}
          dots={false}
          duration={250}
          slidesToShow={7}
          centerPadding={200}
          autoplay
        >
          {providerLists}
        </Providers>
      )}

      {width > 992 && width < 1399 && (
        <Providers
          arrows={false}
          arrowsBlock={false}
          autoplaySpeed={15000}
          dots={false}
          duration={250}
          slidesToShow={6}
          autoplay
        >
          {providerLists}
        </Providers>
      )}

      {width > 768 && width < 991 && (
        <Providers
          arrows={false}
          arrowsBlock={false}
          autoplaySpeed={15000}
          dots={false}
          duration={250}
          slidesToShow={5}
          autoplay
        >
          {providerLists}
        </Providers>
      )}

      {width > 670 && width < 767 && (
        <Providers
          arrows={false}
          arrowsBlock={false}
          autoplaySpeed={15000}
          dots={false}
          duration={250}
          slidesToShow={4}
          autoplay
        >
          {providerLists}
        </Providers>
      )}

      {width < 669 && (
        <Providers
          arrows={false}
          arrowsBlock={false}
          autoplaySpeed={15000}
          dots={false}
          duration={250}
          slidesToShow={3}
          autoplay
        >
          {providerLists}
        </Providers>
      )}

      <Info>
        <InfoHeading>
          {intl.formatMessage({ id: 'footer.Info.heading' })}
        </InfoHeading>

        <InfoDesc>{intl.formatMessage({ id: 'footer.Info.desc' })}</InfoDesc>
      </Info>

      <Sitemap ref={refSitemap}>
        <SitemapState>
          <SitemapColumn>
            <SitemapHeading>
              {intl.formatMessage({ id: 'footer.sitemap.heading_canino' })}
            </SitemapHeading>

            <SitemapMenu>
              <SitemapMenuItem>
                <SitemapMenuBlank
                  href="https://app.alltopasia.com/login/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage({ id: 'footer.sitemap.signin' })}
                </SitemapMenuBlank>
              </SitemapMenuItem>

              <SitemapMenuItem>
                <SitemapMenuBlank
                  href="https://app.alltopasia.com/register/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage({ id: 'footer.sitemap.register' })}
                </SitemapMenuBlank>
              </SitemapMenuItem>

              <SitemapMenuItem>
                <SitemapMenuRouter to="/casinos-online">
                  {intl.formatMessage({ id: 'footer.sitemap.casinos' })}
                </SitemapMenuRouter>
              </SitemapMenuItem>

              <SitemapMenuItem>
                <SitemapMenuRouter to="/slots">
                  {intl.formatMessage({ id: 'footer.sitemap.slots' })}
                </SitemapMenuRouter>
              </SitemapMenuItem>

              <SitemapMenuItem>
                <SitemapMenuRouter to="/sports">
                  {intl.formatMessage({ id: 'footer.sitemap.sports' })}
                </SitemapMenuRouter>
              </SitemapMenuItem>

              <SitemapMenuItem>
                <SitemapMenuRouter to="/e-sports">
                  {intl.formatMessage({ id: 'footer.sitemap.esports' })}
                </SitemapMenuRouter>
              </SitemapMenuItem>
            </SitemapMenu>
          </SitemapColumn>

          <SitemapColumn>
            <SitemapHeading>
              {intl.formatMessage({ id: 'footer.sitemap.heading_events' })}
            </SitemapHeading>

            <SitemapMenu>
              <SitemapMenuItem>
                <SitemapMenuRouter to="/promotions">
                  {intl.formatMessage({ id: 'footer.sitemap.promotions' })}
                </SitemapMenuRouter>
              </SitemapMenuItem>

              <SitemapMenuItem>
                <SitemapMenuBlank
                  href="https://www.alltoppoint.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage({ id: 'footer.sitemap.reward' })}
                </SitemapMenuBlank>
              </SitemapMenuItem>

              {/*
                <SitemapMenuItem>
                  <SitemapMenuRouter to="/">
                    {intl.formatMessage({ id: 'footer.sitemap.events' })}
                  </SitemapMenuRouter>
                </SitemapMenuItem>
              */}
            </SitemapMenu>
          </SitemapColumn>

          <SitemapColumn>
            <SitemapHeading>
              {intl.formatMessage({ id: 'footer.sitemap.heading_business' })}
            </SitemapHeading>

            <SitemapMenu>
              <SitemapMenuItem>
                <SitemapMenuBlank
                  href="/affiliate"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage({ id: 'footer.sitemap.affiliate' })}
                </SitemapMenuBlank>
              </SitemapMenuItem>
            </SitemapMenu>
          </SitemapColumn>

          <SitemapColumn>
            <SitemapHeading>
              {intl.formatMessage({ id: 'footer.sitemap.heading_supporter' })}
            </SitemapHeading>

            <SitemapMenu>
              {/* <SitemapMenuItem>
                <SitemapMenuLink href="tel:0960724119">
                  {intl.formatMessage({ id: 'footer.sitemap.tel' })}
                  {' '}
                  09-6-072-4119
                </SitemapMenuLink>
              </SitemapMenuItem> */}

              <SitemapMenuItem>
                <SitemapMenuBlank
                  href="https://lin.ee/Q7kNgbW"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage({ id: 'footer.sitemap.line' })} @alltopfun
                </SitemapMenuBlank>
              </SitemapMenuItem>
            </SitemapMenu>
          </SitemapColumn>

          <SitemapColumn>
            <SitemapHeading>
              {intl.formatMessage({ id: 'footer.sitemap.languages' })}
            </SitemapHeading>

            <SitemapMenu>
              <IntlContextConsumer>
                {({ languages }) =>
                  languages.map((language) => (
                    <SitemapMenuItem key={language}>
                      <SitemapMenuButton
                        onClick={() => {
                          changeLocale(language);
                        }}
                        type="button"
                      >
                        {languageName[language]}
                      </SitemapMenuButton>
                    </SitemapMenuItem>
                  ))
                }
              </IntlContextConsumer>
            </SitemapMenu>
          </SitemapColumn>

          <SitemapMobile>
            <SitemapMobileButton
              onClick={handleCasinosButton}
              type="button"
              first
            >
              {intl.formatMessage({ id: 'footer.sitemap.heading_canino' })}
            </SitemapMobileButton>

            <SitemapMobileAccordian ref={refCasinosMenu}>
              <SitemapMobileMenu>
                <SitemapMobileMenuItem>
                  <SitemapMobileMenuBlank
                    href="https://app.alltopasia.com/login/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intl.formatMessage({ id: 'footer.sitemap.signin' })}
                  </SitemapMobileMenuBlank>
                </SitemapMobileMenuItem>

                <SitemapMobileMenuItem>
                  <SitemapMobileMenuBlank
                    href="https://app.alltopasia.com/register/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intl.formatMessage({ id: 'footer.sitemap.register' })}
                  </SitemapMobileMenuBlank>
                </SitemapMobileMenuItem>

                <SitemapMobileMenuItem>
                  <SitemapMobileMenuRouter to="/casinos-online">
                    {intl.formatMessage({ id: 'footer.sitemap.casinos' })}
                  </SitemapMobileMenuRouter>
                </SitemapMobileMenuItem>

                <SitemapMobileMenuItem>
                  <SitemapMobileMenuRouter to="/slots">
                    {intl.formatMessage({ id: 'footer.sitemap.slots' })}
                  </SitemapMobileMenuRouter>
                </SitemapMobileMenuItem>

                <SitemapMobileMenuItem>
                  <SitemapMobileMenuRouter to="/sports">
                    {intl.formatMessage({ id: 'footer.sitemap.sports' })}
                  </SitemapMobileMenuRouter>
                </SitemapMobileMenuItem>

                <SitemapMobileMenuItem>
                  <SitemapMobileMenuRouter to="/e-sports">
                    {intl.formatMessage({ id: 'footer.sitemap.esports' })}
                  </SitemapMobileMenuRouter>
                </SitemapMobileMenuItem>
              </SitemapMobileMenu>
            </SitemapMobileAccordian>

            <SitemapMobileButton onClick={handleEventsButton} type="button">
              {intl.formatMessage({ id: 'footer.sitemap.heading_events' })}
            </SitemapMobileButton>

            <SitemapMobileAccordian ref={refEvenetsMenu}>
              <SitemapMobileMenu>
                <SitemapMobileMenuItem>
                  <SitemapMobileMenuRouter to="/promotions">
                    {intl.formatMessage({ id: 'footer.sitemap.promotions' })}
                  </SitemapMobileMenuRouter>
                </SitemapMobileMenuItem>

                <SitemapMobileMenuItem>
                  <SitemapMobileMenuBlank
                    href="https://www.alltoppoint.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intl.formatMessage({ id: 'footer.sitemap.reward' })}
                  </SitemapMobileMenuBlank>
                </SitemapMobileMenuItem>

                {/*
                  <SitemapMobileMenuItem>
                    <SitemapMobileMenuRouter to="/">
                      {intl.formatMessage({ id: 'footer.sitemap.events' })}
                    </SitemapMobileMenuRouter>
                  </SitemapMobileMenuItem>
                */}
              </SitemapMobileMenu>
            </SitemapMobileAccordian>

            <SitemapMobileButton onClick={handleBusinessButton} type="button">
              {intl.formatMessage({ id: 'footer.sitemap.heading_business' })}
            </SitemapMobileButton>

            <SitemapMobileAccordian ref={refBusinessMenu}>
              <SitemapMobileMenu>
                <SitemapMobileMenuItem>
                  <SitemapMobileMenuBlank
                    href="/affiliate"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intl.formatMessage({ id: 'footer.sitemap.affiliate' })}
                  </SitemapMobileMenuBlank>
                </SitemapMobileMenuItem>
              </SitemapMobileMenu>
            </SitemapMobileAccordian>

            <SitemapMobileButton onClick={handleSupportsButton} type="button">
              {intl.formatMessage({ id: 'footer.sitemap.heading_supporter' })}
            </SitemapMobileButton>

            <SitemapMobileAccordian ref={refSupportsMenu}>
              <SitemapMobileMenu>
                <SitemapMobileMenuItem>
                  <SitemapMobileMenuLink href="tel:0960724119">
                    {intl.formatMessage({ id: 'footer.sitemap.tel' })}{' '}
                    09-6-072-4119
                  </SitemapMobileMenuLink>
                </SitemapMobileMenuItem>

                <SitemapMobileMenuItem>
                  <SitemapMobileMenuBlank
                    href="https://lin.ee/Q7kNgbW"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intl.formatMessage({ id: 'footer.sitemap.line' })}{' '}
                    @alltopfun
                  </SitemapMobileMenuBlank>
                </SitemapMobileMenuItem>
              </SitemapMobileMenu>
            </SitemapMobileAccordian>

            <SitemapMobileButton onClick={handleLanguagesButton} type="button">
              {intl.formatMessage({ id: 'footer.sitemap.languages' })}
            </SitemapMobileButton>

            <SitemapMobileAccordian ref={refLanguagesMenu}>
              <SitemapMobileMenu>
                <IntlContextConsumer>
                  {({ languages }) =>
                    languages.map((language) => (
                      <SitemapMobileMenuItem key={language}>
                        <SitemapMobileMenuButton
                          onClick={() => {
                            changeLocale(language);
                          }}
                          type="button"
                        >
                          {languageName[language]}
                        </SitemapMobileMenuButton>
                      </SitemapMobileMenuItem>
                    ))
                  }
                </IntlContextConsumer>
              </SitemapMobileMenu>
            </SitemapMobileAccordian>
          </SitemapMobile>
        </SitemapState>
      </Sitemap>

      <Contact>
        <ContactHeading>
          {intl.formatMessage({ id: 'footer.contact.heading' })}
        </ContactHeading>

        <ContactDesc>
          {intl.formatMessage({ id: 'footer.contact.line' })}{' '}
          <ContactBlank
            href="https://lin.ee/Q7kNgbW"
            target="_blank"
            rel="noopener noreferrer"
          >
            @alltopfun
          </ContactBlank>
          {/* {' '}
          {intl.formatMessage({ id: 'footer.contact.tel' })}
          {' '}
          <ContactBlank href="tel:0960724119">09-6-072-4119</ContactBlank> */}
        </ContactDesc>
      </Contact>

      <Copyright>
        {intl.formatMessage({ id: 'footer.copyright.desc' })}
      </Copyright>

      <Note>{intl.formatMessage({ id: 'footer.note.desc' })}</Note>
    </State>
  );
};

export default Footer;
