import React from 'react';
import PropTypes from 'prop-types';
import theme from 'theme';
import { ThemeProvider } from 'styled-components';

import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { store, persistor } from 'store';

const Providers = (props) => {
  const { children } = props;

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          {children}
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

Providers.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Providers;
