import styled from 'styled-components';
import Slider from 'infinite-react-carousel';
import { Link } from 'gatsby-plugin-intl';
import { calcRem } from 'utils';

const State = styled.footer`
  width: 100%;
  height: auto;

  @media (max-width: 575px) {
    padding: 0 0 ${calcRem(122)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: 0 0 ${calcRem(122)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 0 0 ${calcRem(64)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 0 0 ${calcRem(64)};
  }

  @media (min-width: 1200px) {
    padding: 0 0 ${calcRem(64)};
  }
`;

const Register = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${(props) => props.theme.palette.theme};

  @media (max-width: 575px) {
    flex-direction: column;
    padding: ${calcRem(32)} ${calcRem(32)};
    margin: 0 auto ${calcRem(32)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: ${calcRem(16)} ${calcRem(16)};
    margin: 0 auto ${calcRem(32)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: ${calcRem(16)} ${calcRem(32)};
    margin: 0 auto ${calcRem(32)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: ${calcRem(16)} ${calcRem(32)};
    margin: 0 auto ${calcRem(32)};
  }

  @media (min-width: 1200px) {
    padding: ${calcRem(16)} ${calcRem(32)};
    margin: 0 auto ${calcRem(32)};
  }
`;

const RegisterTitle = styled.h3`
  color: #000000;
  font-weight: 400;

  @media (max-width: 575px) {
    font-size: ${calcRem(32)};
    font-weight: 500;
    text-align: center;
    margin: 0 0 ${calcRem(28)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 ${calcRem(8)} 0 0;
    font-size: ${calcRem(28)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 ${calcRem(8)} 0 0;
    font-size: ${calcRem(28)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 ${calcRem(8)} 0 0;
    font-size: ${calcRem(28)};
  }

  @media (min-width: 1200px) {
    margin: 0 ${calcRem(8)} 0 0;
    font-size: ${calcRem(28)};
  }
`;

const RegisterButton = styled.a`
  display: block;
  color: #000000;
  border-radius: ${calcRem(4)};
  border: 1px solid #000000;
  transition: all 0.25s ease;
  font-weight: 500;
  white-space: nowrap;

  @media (max-width: 575px) {
    font-size: ${calcRem(24)};
    padding: ${calcRem(12)} ${calcRem(32)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: ${calcRem(12)} ${calcRem(32)};
    margin: 0 0 0 ${calcRem(8)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: ${calcRem(8)} ${calcRem(32)};
    margin: 0 0 0 ${calcRem(8)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: ${calcRem(8)} ${calcRem(32)};
    margin: 0 0 0 ${calcRem(8)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 1200px) {
    padding: ${calcRem(8)} ${calcRem(32)};
    margin: 0 0 0 ${calcRem(8)};
    font-size: ${calcRem(24)};
  }

  @media not all and (pointer: coarse) {
    &:hover{
      background-color: #000000;
      color: ${(props) => props.theme.palette.theme};
    }
  }
`;

const Providers = styled(Slider)`
  border-bottom: 1px solid #1b1e21;

  @media (max-width: 575px) {
    margin: 0 auto ${calcRem(32)};
    padding: 0 0 ${calcRem(32)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 auto ${calcRem(32)};
    padding: 0 0 ${calcRem(32)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 auto ${calcRem(64)};
    padding: 0 0 ${calcRem(32)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 auto ${calcRem(64)};
    padding: 0 0 ${calcRem(32)};
  }

  @media (min-width: 1200px) {
    margin: 0 auto ${calcRem(64)};
    padding: 0 0 ${calcRem(32)};
  }
`;

const ProvidersBlank = styled.a`
  display: block;

  @media (max-width: 575px) {
    padding: 0 ${calcRem(8)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: 0 ${calcRem(8)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 0 ${calcRem(16)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 0 ${calcRem(16)};
  }

  @media (min-width: 1200px) {
    padding: 0 ${calcRem(16)};
  }
`;

const Info = styled.div`
  width: 100%;
  max-width: 1325px;
  line-height: 1.25;
  text-align: center;

  @media (max-width: 575px) {
    padding: 0 ${calcRem(16)};
    margin: 0 auto ${calcRem(32)};
    font-size: ${calcRem(21)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: 0 ${calcRem(16)};
    margin: 0 auto ${calcRem(32)};
    font-size: ${calcRem(18)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 0 ${calcRem(32)};
    margin: 0 auto ${calcRem(64)};
    font-size: ${calcRem(18)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 0 ${calcRem(32)};
    margin: 0 auto ${calcRem(64)};
    font-size: ${calcRem(18)};
  }

  @media (min-width: 1200px) {
    padding: 0 ${calcRem(32)};
    margin: 0 auto ${calcRem(64)};
    font-size: ${calcRem(18)};
  }
`;

const InfoHeading = styled.h3`
  font-weight: 500;

  @media (max-width: 575px) {
    margin: 0 0 ${calcRem(8)};
    font-size: ${calcRem(26)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 ${calcRem(8)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 ${calcRem(16)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 ${calcRem(16)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 1200px) {
    margin: 0 0 ${calcRem(16)};
    font-size: ${calcRem(24)};
  }
`;

const InfoDesc = styled.p`
  margin: 0;
  line-height: 1.25;
  text-align: center;

  @media (max-width: 575px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(18)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(18)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(18)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(18)};
  }
`;

const Sitemap = styled.div`
  @media (max-width: 575px) {
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 ${calcRem(32)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    background-color: #181818;
    border-top: solid ${calcRem(1)} #1b1e21;
    border-bottom: solid ${calcRem(1)} #1b1e21;
    padding: ${calcRem(32)} 0;
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    background-color: #181818;
    border-top: solid ${calcRem(1)} #1b1e21;
    border-bottom: solid ${calcRem(1)} #1b1e21;
    padding: ${calcRem(32)} 0;
    margin: 0 0 ${calcRem(64)};
  }

  @media (min-width: 1200px) {
    background-color: #181818;
    border-top: solid ${calcRem(1)} #1b1e21;
    border-bottom: solid ${calcRem(1)} #1b1e21;
    padding: ${calcRem(32)} 0;
    margin: 0 0 ${calcRem(64)};
  }
`;

const SitemapState = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1197px;
  margin: 0 auto;
  line-height: 1.25;

  @media (max-width: 575px) {
    padding: 0 ${calcRem(16)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: 0 ${calcRem(16)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 0 ${calcRem(32)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 0 ${calcRem(32)};
  }

  @media (min-width: 1200px) {
    padding: 0 ${calcRem(32)};
  }
`;

const SitemapColumn = styled.div`
  @media (max-width: 575px) {
    display: none;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const SitemapHeading = styled.h4`
  font-weight: 500;

  @media (max-width: 575px) {
    margin: 0 0 ${calcRem(4)};
    font-size: ${calcRem(21)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 0 ${calcRem(4)};
    font-size: ${calcRem(21)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 0 ${calcRem(8)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 0 ${calcRem(8)};
    font-size: ${calcRem(24)};
  }

  @media (min-width: 1200px) {
    margin: 0 0 ${calcRem(8)};
    font-size: ${calcRem(24)};
  }
`;

const SitemapMenu = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const SitemapMenuItem = styled.li`
  line-height: 1.5;
  position: relative;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const SitemapMenuRouter = styled(Link)`
  display: flex;
  align-items: center;
  color: #CC9933;

  @media (max-width: 575px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(18)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(18)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(18)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(18)};
  }

  @media not all and (pointer: coarse) {
    &:hover {
      color: #CC9933;
      text-decoration: underline;
    }
  }
`;

const SitemapMenuLink = styled.a`
  display: flex;
  align-items: center;
  color: #CC9933;

  @media (max-width: 575px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(18)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(18)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(18)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(18)};
  }

  @media not all and (pointer: coarse) {
    &:hover {
      color: #CC9933;
      text-decoration: underline;
    }
  }
`;

const SitemapMenuBlank = styled.a`
  display: flex;
  align-items: center;
  color: #CC9933;

  @media (max-width: 575px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(18)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(18)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(18)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(18)};
  }

  @media not all and (pointer: coarse) {
    &:hover {
      color: #CC9933;
      text-decoration: underline;
    }
  }
`;

const SitemapMenuButton = styled.button`
  padding: 0;
  background: transparent;
  border: none;
  color: #CC9933;

  @media (max-width: 575px) {
    font-size: ${calcRem(21)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: ${calcRem(18)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: ${calcRem(18)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: ${calcRem(18)};
  }

  @media (min-width: 1200px) {
    font-size: ${calcRem(18)};
  }

  @media not all and (pointer: coarse) {
    &:hover {
      color: #CC9933;
      text-decoration: underline;
    }
  }
`;

const SitemapMobile = styled.div`
  @media (max-width: 575px) {
    width: 100%;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    display: none;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) {
    display: none;
  }
`;

const SitemapMobileButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${calcRem(16)} 0;
  line-height: 1.5;
  background: transparent;
  border: none;
  border-bottom: ${calcRem(1)} solid #1b1e21;
  color: #c0c0c0;
  font-size: ${calcRem(30)};
  font-weight: 500;

  ${(props) => props.first && `
    border-top: ${calcRem(1)} solid #1b1e21;
  `}
`;

const SitemapMobileAccordian = styled.div`
  width: 100%;
  height: 0;
  overflow: hidden;
`;

const SitemapMobileMenu = styled.ul`
  width: 100%;
  padding: ${calcRem(16)} 0;
  margin: 0;
  list-style: none;
  border-bottom: ${calcRem(1)} solid #1b1e21;
  overflow: hidden;
`;

const SitemapMobileMenuItem = styled.li`
  line-height: 1.5;
  position: relative;
`;

const SitemapMobileMenuRouter = styled(Link)`
  display: block;
  color: #CC9933;
  font-size: ${calcRem(28)};

  @media not all and (pointer: coarse) {
    &:hover {
      color: #CC9933;
    }
  }
`;

const SitemapMobileMenuLink = styled.a`
  display: block;
  color: #CC9933;
  font-size: ${calcRem(28)};

  @media not all and (pointer: coarse) {
    &:hover {
      color: #CC9933;
    }
  }
`;

const SitemapMobileMenuBlank = styled.a`
  display: block;
  color: #CC9933;
  font-size: ${calcRem(28)};

  @media not all and (pointer: coarse) {
    &:hover {
      color: #CC9933;
    }
  }
`;

const SitemapMobileMenuButton = styled.button`
  padding: 0;
  background: transparent;
  border: none;
  color: #CC9933;
  font-size: ${calcRem(28)};

  @media not all and (pointer: coarse) {
    &:hover {
      color: #CC9933;
    }
  }
`;

const Contact = styled.div`
  width: 100%;
  max-width: 1325px;
  line-height: 1.25;
  text-align: center;

  @media (max-width: 575px) {
    margin: 0 auto ${calcRem(32)};
    font-size: ${calcRem(21)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 auto ${calcRem(32)};
    font-size: ${calcRem(18)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 auto ${calcRem(64)};
    font-size: ${calcRem(18)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 auto ${calcRem(64)};
    font-size: ${calcRem(18)};
  }

  @media (min-width: 1200px) {
    margin: 0 auto ${calcRem(64)};
    font-size: ${calcRem(18)};
  }
`;

const ContactHeading = styled.h3`
  margin: 0;
  line-height: 1.25;
  font-weight: 400;
  text-align: center;

  @media (max-width: 575px) {
    padding: 0 ${calcRem(16)};
    font-size: ${calcRem(21)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: 0 ${calcRem(16)};
    font-size: ${calcRem(18)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 0 ${calcRem(32)};
    font-size: ${calcRem(18)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 0 ${calcRem(32)};
    font-size: ${calcRem(18)};
  }

  @media (min-width: 1200px) {
    padding: 0 ${calcRem(32)};
    font-size: ${calcRem(18)};
  }
`;

const ContactDesc = styled.p`
  margin: 0;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const ContactBlank = styled.a`
  color: #c0c0c0;
  text-decoration: underline;

  @media not all and (pointer: coarse) {
    &:hover {
      color: #c0c0c0;
      text-decoration: none;
    }
  }
`;

const Note = styled.div`
  width: 100%;
  max-width: 1325px;
  margin: 0 auto;
  line-height: 1.25;
  color:  #757575;
  text-align: center;

  @media (max-width: 575px) {
    padding: 0 ${calcRem(16)};
    font-size: ${calcRem(18)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: 0 ${calcRem(16)};
    font-size: ${calcRem(16)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 0 ${calcRem(32)};
    font-size: ${calcRem(16)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 0 ${calcRem(32)};
    font-size: ${calcRem(16)};
  }

  @media (min-width: 1200px) {
    padding: 0 ${calcRem(32)};
    font-size: ${calcRem(16)};
  }
`;

const Copyright = styled.div`
  width: 100%;
  max-width: 1325px;
  margin: 0 auto;
  line-height: 1.25;
  color:  #757575;
  text-align: center;

  @media (max-width: 575px) {
    padding: 0 ${calcRem(16)};
    font-size: ${calcRem(18)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: 0 ${calcRem(16)};
    font-size: ${calcRem(16)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 0 ${calcRem(32)};
    font-size: ${calcRem(16)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 0 ${calcRem(32)};
    font-size: ${calcRem(16)};
  }

  @media (min-width: 1200px) {
    padding: 0 ${calcRem(32)};
    font-size: ${calcRem(16)};
  }
`;

export {
  State,
  Contact,
  ContactBlank,
  ContactDesc,
  ContactHeading,
  Copyright,
  Info,
  InfoDesc,
  InfoHeading,
  Note,
  Providers,
  ProvidersBlank,
  Register,
  RegisterButton,
  RegisterTitle,
  Sitemap,
  SitemapColumn,
  SitemapHeading,
  SitemapMenu,
  SitemapMenuBlank,
  SitemapMenuButton,
  SitemapMenuItem,
  SitemapMenuLink,
  SitemapMenuRouter,
  SitemapMobile,
  SitemapMobileAccordian,
  SitemapMobileButton,
  SitemapMobileMenu,
  SitemapMobileMenuBlank,
  SitemapMobileMenuButton,
  SitemapMobileMenuItem,
  SitemapMobileMenuLink,
  SitemapMobileMenuRouter,
  SitemapState,
};
