import { combineReducers } from 'redux';
import userInterface from './userinterface';
import userInterfacWhitelist from './userinterfacwhitelist';

const rootReducer = combineReducers({
  userInterface,
  userInterfacWhitelist,
});

export default rootReducer;
