import styled, { css } from 'styled-components';
import { calcRem } from 'utils';

const State = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: fixed;
  left: 0;
  transform: translate(0, -100%);
  background-color: ${(props) => props.theme.palette.black};
  overflow: auto;
  z-index: 99;

  @media (max-width: 575px) {
    height: calc(100% - ${calcRem(278)});
    top: ${calcRem(194)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    height: calc(100% - ${calcRem(178)});
    top: ${calcRem(90)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    height: calc(100% - ${calcRem(90)});
    top: ${calcRem(90)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) {
    display: none;
  }
`;

const Nav = styled.nav`
  display: none;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  
  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
    /* height: 100%; */
  }

  @media (min-width: 768px) and (max-width: 991px) {
    /* height: 100%; */
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const NavLists = styled.ul`
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
`;

const NavItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center; 
  position: relative;

  @media (max-width: 575px) {
    width: 50%;
    height: 50vw;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: 25%;
    height: 25vw;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 25%;
    height: 25vw;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }

  ${(props) => props.item1 && css`
    @media (max-width: 575px) {
      &:before {
        content: "";
        background-color: ${props.theme.palette.border};
        width: 50%;
        height: ${calcRem(1)};
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        bottom: 0;
        z-index: 999;
      }

      &:after {
        content: "";
        background-color: ${props.theme.palette.border};
        width: ${calcRem(1)};
        height: 50%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
        z-index: 999;
      }
    }

    @media (min-width: 576px) and (max-width: 767px) {
      &:before {
        content: "";
        background-color: ${props.theme.palette.border};
        width: 50%;
        height: ${calcRem(1)};
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        bottom: 0;
        z-index: 999;
      }

      &:after {
        content: "";
        background-color: ${props.theme.palette.border};
        width: ${calcRem(1)};
        height: 50%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
        z-index: 999;
      }
    }

    @media (min-width: 768px) and (max-width: 991px) {
      &:before {
        content: "";
        background-color: ${props.theme.palette.border};
        width: 50%;
        height: ${calcRem(1)};
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        bottom: 0;
        z-index: 999;
      }

      &:after {
        content: "";
        background-color: ${props.theme.palette.border};
        width: ${calcRem(1)};
        height: 50%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
        z-index: 999;
      }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
    }

    @media (min-width: 1200px) {
    }
  `};

  ${(props) => props.item2 && css`
    @media (max-width: 575px) {
      &:before {
        content: "";
        background-color: ${props.theme.palette.border};
        width: 50%;
        height: ${calcRem(1)};
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        bottom: 0;
        z-index: 999;
      }
    }

    @media (min-width: 576px) and (max-width: 767px) {
      &:before {
        content: "";
        background-color: ${props.theme.palette.border};
        width: 50%;
        height: ${calcRem(1)};
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        bottom: 0;
        z-index: 999;
      }

      &:after {
        content: "";
        background-color: ${props.theme.palette.border};
        width: ${calcRem(1)};
        height: 50%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
        z-index: 999;
      }
    }

    @media (min-width: 768px) and (max-width: 991px) {
      &:before {
        content: "";
        background-color: ${props.theme.palette.border};
        width: 50%;
        height: ${calcRem(1)};
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        bottom: 0;
        z-index: 999;
      }

      &:after {
        content: "";
        background-color: ${props.theme.palette.border};
        width: ${calcRem(1)};
        height: 50%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
        z-index: 999;
      }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
    }

    @media (min-width: 1200px) {
    }
  `};

  ${(props) => props.item3 && css`
    @media (max-width: 575px) {
      &:before {
        content: "";
        background-color: ${props.theme.palette.border};
        width: 50%;
        height: ${calcRem(1)};
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        bottom: 0;
        z-index: 999;
      }

      &:after {
        content: "";
        background-color: ${props.theme.palette.border};
        width: ${calcRem(1)};
        height: 50%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
        z-index: 999;
      }
    }

    @media (min-width: 576px) and (max-width: 767px) {      &:before {
        content: "";
        background-color: ${props.theme.palette.border};
        width: 50%;
        height: ${calcRem(1)};
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        bottom: 0;
        z-index: 999;
      }

      &:after {
        content: "";
        background-color: ${props.theme.palette.border};
        width: ${calcRem(1)};
        height: 50%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
        z-index: 999;
      }
    }

    @media (min-width: 768px) and (max-width: 991px) {
      &:before {
        content: "";
        background-color: ${props.theme.palette.border};
        width: 50%;
        height: ${calcRem(1)};
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        bottom: 0;
        z-index: 999;
      }

      &:after {
        content: "";
        background-color: ${props.theme.palette.border};
        width: ${calcRem(1)};
        height: 50%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
        z-index: 999;
      }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
    }

    @media (min-width: 1200px) {
    }
  `};

  ${(props) => props.item4 && css`
    @media (max-width: 575px) {
      &:before {
        content: "";
        background-color: ${props.theme.palette.border};
        width: 50%;
        height: ${calcRem(1)};
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        bottom: 0;
        z-index: 999;
      }
    }

    @media (min-width: 576px) and (max-width: 767px) {
      &:before {
        content: "";
        background-color: ${props.theme.palette.border};
        width: 50%;
        height: ${calcRem(1)};
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        bottom: 0;
        z-index: 999;
      }
    }

    @media (min-width: 768px) and (max-width: 991px) {
      &:before {
        content: "";
        background-color: ${props.theme.palette.border};
        width: 50%;
        height: ${calcRem(1)};
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        bottom: 0;
        z-index: 999;
      }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
    }

    @media (min-width: 1200px) {
    }
  `};

  ${(props) => props.item5 && css`
    @media (max-width: 575px) {
      &:before {
        content: "";
        background-color: ${props.theme.palette.border};
        width: 50%;
        height: ${calcRem(1)};
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        bottom: 0;
        z-index: 999;
      }

      &:after {
        content: "";
        background-color: ${props.theme.palette.border};
        width: ${calcRem(1)};
        height: 50%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
        z-index: 999;
      }
    }

    @media (min-width: 576px) and (max-width: 767px) {
      &:after {
        content: "";
        background-color: ${props.theme.palette.border};
        width: ${calcRem(1)};
        height: 50%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
        z-index: 999;
      }
    }

    @media (min-width: 768px) and (max-width: 991px) {
      &:after {
        content: "";
        background-color: ${props.theme.palette.border};
        width: ${calcRem(1)};
        height: 50%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
        z-index: 999;
      }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
    }

    @media (min-width: 1200px) {
    }
  `};

  ${(props) => props.item6 && css`
    @media (max-width: 575px) {
      &:before {
        content: "";
        background-color: ${props.theme.palette.border};
        width: 50%;
        height: ${calcRem(1)};
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
        bottom: 0;
        z-index: 999;
      }
    }

    @media (min-width: 576px) and (max-width: 767px) {
      &:after {
        content: "";
        background-color: ${props.theme.palette.border};
        width: ${calcRem(1)};
        height: 50%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
        z-index: 999;
      }
    }

    @media (min-width: 768px) and (max-width: 991px) {
      &:after {
        content: "";
        background-color: ${props.theme.palette.border};
        width: ${calcRem(1)};
        height: 50%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
        z-index: 999;
      }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
    }

    @media (min-width: 1200px) {
    }
  `};

  ${(props) => props.item7 && css`
    @media (max-width: 575px) {
      &:after {
        content: "";
        background-color: ${props.theme.palette.border};
        width: ${calcRem(1)};
        height: 50%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
        z-index: 999;
      }
    }

    @media (min-width: 576px) and (max-width: 767px) {
      &:after {
        content: "";
        background-color: ${props.theme.palette.border};
        width: ${calcRem(1)};
        height: 50%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
        z-index: 999;
      }
    }

    @media (min-width: 768px) and (max-width: 991px) {
      &:after {
        content: "";
        background-color: ${props.theme.palette.border};
        width: ${calcRem(1)};
        height: 50%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
        z-index: 999;
      }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
    }

    @media (min-width: 1200px) {
    }
  `};

  ${(props) => props.item8 && css`
  `};
`;

export {
  State,
  Nav,
  NavItem,
  NavLists,
};
