import styled, { css } from 'styled-components';
import { Link } from 'gatsby-plugin-intl';
import { ImageResponsive, calcRem } from 'utils';

const State = styled.header`
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.palette.black};
  border-bottom: ${calcRem(1)} solid ${(props) => props.theme.palette.border};
  z-index: 999;

  @media (max-width: 575px) {
    height: ${calcRem(194)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    height: ${calcRem(90)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    height: ${calcRem(90)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    height: ${calcRem(90)};
  }

  @media (min-width: 1200px) {
    height: ${calcRem(90)};
  }
`;

const Brand = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 575px) {
    order: 2;
    width: ${calcRem(90)};
    height: ${calcRem(106)};
    margin: 0 ${calcRem(16)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: ${calcRem(74)};
    height: ${calcRem(90)};
    margin: 0 auto 0 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: ${calcRem(177)};
    height: ${calcRem(90)};
    margin: 0 auto 0 0;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  width: ${calcRem(177)};
  height: ${calcRem(90)};
    margin: 0 ${calcRem(16)} 0 0;
  }

  @media (min-width: 1200px) {
  width: ${calcRem(177)};
  height: ${calcRem(90)};
  margin: 0 ${calcRem(16)} 0 0;
  }
`;

const BrandRouter = styled(Link)`
  display: block;

  @media (max-width: 575px) {
    width: ${calcRem(90)};
    height: ${calcRem(90)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: ${calcRem(74)};
    height: ${calcRem(74)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: ${calcRem(177)};
    height: ${calcRem(48)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: ${calcRem(177)};
    height: ${calcRem(48)};
  }

  @media (min-width: 1200px) {
    width: ${calcRem(177)};
    height: ${calcRem(48)};
  }
`;

const BrandImage = styled(ImageResponsive)`
  width: 100%;
  height: auto;

  ${(props) => props.mobile && css`
    @media (min-width: 768px) and (max-width: 991px) {
      display: none;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      display: none;
    }

    @media (min-width: 1200px) {
      display: none;
    }
  `};

  ${(props) => props.desktop && css`
    @media (max-width: 575px) {
      display: none;
    }

    @media (min-width: 576px) and (max-width: 767px) {
      display: none;
    }
  `};
`;

const Nav = styled.nav`
  flex: 1;

  @media (max-width: 575px) {
    display: none;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    display: none;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 ${calcRem(16)};
  }

  @media (min-width: 1200px) {
    margin: 0 ${calcRem(16)};
  }
`;

const NavLists = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const NavItem = styled.li`
  flex: 1;
  display: flex;
  justify-content: center;
  list-style: none;
  width: auto;
  height: ${calcRem(90)};
  position: relative;

  ${(props) => props.item1 && css`
    &:before {
      content: "";
      background-color: ${props.theme.palette.border};
      width: ${calcRem(1)};
      height: ${calcRem(32)};
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 0;
      z-index: 999;
    }

    &:after {
      content: "";
      background-color: ${props.theme.palette.border};
      width: ${calcRem(1)};
      height: ${calcRem(32)};
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 0;
      z-index: 999;
    }
  `};

  ${(props) => props.item2 && css`
    &:after{
      content: "";
      background-color: ${props.theme.palette.border};
      width: ${calcRem(1)};
      height: ${calcRem(32)};
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 0;
      z-index: 999;
    }
  `};

  ${(props) => props.item3 && css`
    &:after{
      content: "";
      background-color: ${props.theme.palette.border};
      width: ${calcRem(1)};
      height: ${calcRem(32)};
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 0;
      z-index: 999;
    }
  `};

  ${(props) => props.item4 && css`
    &:after{
      content: "";
      background-color: ${props.theme.palette.border};
      width: ${calcRem(1)};
      height: ${calcRem(32)};
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 0;
      z-index: 999;
    }
  `};

  ${(props) => props.item5 && css`
    &:after{
      content: "";
      background-color: ${props.theme.palette.border};
      width: ${calcRem(1)};
      height: ${calcRem(32)};
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 0;
      z-index: 999;
    }
  `};

  ${(props) => props.item6 && css`
    &:after{
      content: "";
      background-color: ${props.theme.palette.border};
      width: ${calcRem(1)};
      height: ${calcRem(32)};
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 0;
      z-index: 999;
    }
  `};

  ${(props) => props.item7 && css`
    &:after{
      content: "";
      background-color: ${props.theme.palette.border};
      width: ${calcRem(1)};
      height: ${calcRem(32)};
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 0;
      z-index: 999;
    }
  `};

  ${(props) => props.item8 && css`
  `};

  &:last-child {
    @media (max-width: 575px) {
      display: none;
    }

    @media (min-width: 576px) and (max-width: 767px) {
      display: none;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      display: none;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      display: none;
    }

    @media (min-width: 1200px) {
      /* margin: 0 0 0 ${calcRem(30)}; */
    }
  }
`;

const Request = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 575px) {
    order: 4;
    width: 100%;
    height: ${calcRem(88)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin: 0 ${calcRem(16)} 0 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 ${calcRem(16)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    margin: 0 ${calcRem(16)};
  }

  @media (min-width: 1200px) {
    margin: 0 ${calcRem(16)};
  }
`;

const RequestButton = styled.div`
  flex-shrink: 0;

  @media (max-width: 575px) {
    width: calc(50% - ${calcRem(8)});
    height: ${calcRem(54)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: ${calcRem(136)};
    height: ${calcRem(54)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: ${calcRem(136)};
  height: ${calcRem(42)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: ${calcRem(136)};
  height: ${calcRem(42)};
  }

  @media (min-width: 1200px) {
  width: ${calcRem(136)};
  height: ${calcRem(42)};
  }

  ${(props) => props.first && css`
    @media (max-width: 575px) {
      margin: 0 ${calcRem(8)} 0 0;
    }

    @media (min-width: 576px) and (max-width: 767px) {
      margin: 0 ${calcRem(8)} 0 0;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      margin: 0 ${calcRem(8)} 0 0;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      margin: 0 ${calcRem(8)} 0 0;
    }

    @media (min-width: 1200px) {
      margin: 0 ${calcRem(8)} 0 0;
    }
  `};

  ${(props) => props.last && css`
    margin: 0 0 0 ${calcRem(8)};

    @media (max-width: 575px) {
      margin: 0 0 0 ${calcRem(8)};
    }

    @media (min-width: 576px) and (max-width: 767px) {
      margin: 0 0 0 ${calcRem(8)};
    }

    @media (min-width: 768px) and (max-width: 991px) {
      margin: 0 0 0 ${calcRem(8)};
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      margin: 0 0 0 ${calcRem(8)};
    }

    @media (min-width: 1200px) {
      margin: 0 0 0 ${calcRem(8)};
    }
  `};
`;

const Translate = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: 575px) {
    order: 3;
    flex: 1;
    justify-content: flex-end;
    margin: 0 0 0 ${calcRem(16)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    justify-content: center;
    margin: 0 ${calcRem(16)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    justify-content: center;
    margin: 0 ${calcRem(16)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    justify-content: center;
    margin: 0 0 0 ${calcRem(16)};
  }

  @media (min-width: 1200px) {
    justify-content: center;
    margin: 0 0 0 ${calcRem(16)};
  }
`;

const TranslateButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  position: relative;
  z-index: 99;

  @media (max-width: 575px) {
    padding: ${calcRem(8)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
  }
`;

const TranslateIcon = styled(ImageResponsive)`
  width: 100%;
  height: auto;
  
  @media (max-width: 575px) {
    width: ${calcRem(42)};
    height: ${calcRem(42)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: ${calcRem(36)};
    height: ${calcRem(36)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: ${calcRem(36)};
    height: ${calcRem(36)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: ${calcRem(36)};
    height: ${calcRem(36)};
  }

  @media (min-width: 1200px) {
    width: ${calcRem(36)};
    height: ${calcRem(36)};
  }
`;

const TranslateModal = styled.div`
  position: absolute;
  line-height: 0;
  background-color: ${(props) => props.theme.palette.black};
  border: ${calcRem(1)} solid ${(props) => props.theme.palette.border};
  z-index: 9;

  @media (max-width: 575px) {
    padding: ${calcRem(52)} 0 0 0;
    top: ${calcRem(24)};
    right: ${calcRem(-1)};
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: ${calcRem(8)};
    top: ${calcRem(90)};
    left: 50%;
    transform: translate(-50%, 0);
    border-top: none;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: ${calcRem(8)};
    top: ${calcRem(90)};
    left: 50%;
    transform: translate(-50%, 0);
    border-top: none;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: ${calcRem(8)};
    top: ${calcRem(90)};
    left: 50%;
    transform: translate(-50%, 0);
    border-top: none;
  }

  @media (min-width: 1200px) {
    padding: ${calcRem(8)};
    top: ${calcRem(90)};
    left: 50%;
    transform: translate(-50%, 0);
    border-top: none;
  }
`;

const Burger = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 575px) {
    flex: 1;
    order: 1;
    justify-content: flex-start;
    margin: 0 ${calcRem(16)} 0 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    justify-content: center;
    margin: 0 0 0 ${calcRem(16)};
  }

  @media (min-width: 768px) and (max-width: 991px) {
    justify-content: center;
    margin: 0 0 0 ${calcRem(16)};
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) {
    display: none;
  }
`;

const BurgerButton = styled.a`
    display: block;
    width: ${calcRem(34)};
    height: ${calcRem(27)};
    position: relative;
  
  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    /* display: none; */
  }

  @media (min-width: 1200px) {
    /* display: none; */
  }
`;

const BurgerLine = styled.span`
  display: block;
  width: 100%;
  height: ${calcRem(2)};
  position: absolute;
  left: 0;
  background-color: ${(props) => props.theme.palette.base};

  ${(props) => props.line1 && css`
    top: 0;
  `};

  ${(props) => props.line2 && css`
    top: 50%;
    transform: translate(0, -50%);
  `};

  ${(props) => props.line3 && css`
      top: 50%;
      transform: translate(0, -50%);
  `};

  ${(props) => props.line4 && css`
    bottom: 0;
  `};
`;

const Modal = styled.div`
  ${(props) => props.line1 && css`
  `};

  ${(props) => props.line2 && css`
  `};

  ${(props) => props.line3 && css`
  `};

  ${(props) => props.line4 && css`
  `};
`;

export {
  State,
  Brand,
  BrandImage,
  BrandRouter,
  Burger,
  BurgerButton,
  BurgerLine,
  Modal,
  Nav,
  NavItem,
  NavLists,
  Request,
  RequestButton,
  Translate,
  TranslateButton,
  TranslateIcon,
  TranslateModal,
};
