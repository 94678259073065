import React from 'react';
import PropTypes from 'prop-types';
import Providers from 'providers';
import Meta from 'meta';
import Notification from './notification';
import Aside from './aside';
import Bacon from './bacon';
import Footer from './footer';
import Header from './header';
import Navbar from './navbar';
import 'styles/styles.css';

const Layouts = (props) => {
  const {
    children,
    description,
    pathname,
    title,
    image,
  } = props;

  return (
    <Providers>
      <Meta
        title={title}
        description={description}
        image={image}
        pathname={pathname}
      />

      <Header pathname={pathname} />
      <Notification />
      {children}
      <Footer />
      <Navbar />
      <Bacon />
      <Aside />
    </Providers>
  );
};

Layouts.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default Layouts;
