import styled from 'styled-components';
import { calcRem } from 'utils';

const State = styled.div`
  width: 100vw;
  height: auto;
  padding: ${calcRem(64)} 0;
  position: fixed;
  top: ${calcRem(90)};
  left: 0;

  background-color: #000000;
  border-bottom: 0.0625rem solid #1b1e21;

  @media (max-width: 575px) {
    display: none;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    display: none;
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    display: none;
  }
  
  @media (min-width: 992px) and (max-width: 1199px) {
  }
  
  @media (min-width: 1200px) {
  }
`;

const Providers = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
  }
  
  @media (min-width: 992px) and (max-width: 1199px) {
  }
  
  @media (min-width: 1200px) {
  }
`;

const ProviderApp = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 ${calcRem(16)} ${calcRem(16)} 0;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
  }
  
  @media (min-width: 992px) and (max-width: 1199px) {
  }
  
  @media (min-width: 1200px) {
  }
`;

const ProviderCover = styled.div`
  margin: 0 0 ${calcRem(16)};
  position: relative;
  transition: transform 0.25s ease;
  
  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    width: ${calcRem(100)};
  }
  
  @media (min-width: 992px) and (max-width: 1199px) {
    width: ${calcRem(120)};
  }
  
  @media (min-width: 1200px) {
    width: ${calcRem(140)};
  }

  @media not all and (pointer: coarse) {
    ${ProviderApp}:hover & {
      transform: translate(0, ${calcRem(-8)});
    }
  }
`;

const ProviderTitle = styled.span`
  display: block;
  color: #c0c0c0;
  font-size: ${calcRem(18)};
  text-align: center;
  transition: color 0.25s ease;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
  }
  
  @media (min-width: 992px) and (max-width: 1199px) {
  }
  
  @media (min-width: 1200px) {
  }

  @media not all and (pointer: coarse) {
    ${ProviderApp}:hover & {
      color: #CC9933;
    }
  }
`;

export {
  State,
  Providers,
  ProviderApp,
  ProviderCover,
  ProviderTitle,
};
