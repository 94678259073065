const initialState = {
  burger: false,
};

const userInterfacWhitelist = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MODAL_OPEN':
      return {
        ...state,
        burger: true,
      };

    case 'SET_MODAL_CLOSE':
      return {
        ...state,
        burger: false,
      };

    default:
      return state;
  }
};

export default userInterfacWhitelist;
