import styled, { css } from 'styled-components';
import { Link } from 'gatsby-plugin-intl';
import { calcRem } from 'utils';

const State = styled.div`
  width: 100%;
  position: relative;
  background-color: red;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {

  }

  @media (min-width: 992px) and (max-width: 1199px) {

  }

  @media (min-width: 1200px) {
  }
`;

const Inner = styled.div`
  width: 100%;
  position: relative;
  color: #ffffff;
  font-size: ${calcRem(22)};
  text-align: center;

  @media (max-width: 575px) {
    padding: ${calcRem(8)} 0;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: ${calcRem(8)} 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: ${calcRem(16)} 0;

  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding: ${calcRem(16)} 0;

  }

  @media (min-width: 1200px) {
    padding: ${calcRem(16)} 0;
  }

  a {
    color: #000000;
    text-decoration: underline;
    font-weight: 700;

    &:hover {
      text-decoration: none;
    }
  }
`;

const Hilight = styled.span`
  font-weight: 700;

  @media (max-width: 575px) {
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {

  }

  @media (min-width: 992px) and (max-width: 1199px) {

  }

  @media (min-width: 1200px) {
  }
`;

const Newline = styled.span`
  @media (max-width: 575px) {
    display: block;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    display: block;
  }

  @media (min-width: 768px) and (max-width: 991px) {

  }

  @media (min-width: 992px) and (max-width: 1199px) {

  }

  @media (min-width: 1200px) {
  }
`;

export {
  State,
  Inner,
  Hilight,
  Newline,
};
