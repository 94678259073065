import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { ButtonBacon } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'gatsby-plugin-intl';
import { State, Nav, NavItem, NavLists } from './styled';

const Bacon = () => {
  const [render, setRender] = useState(false);
  const intl = useIntl();
  const refState = useRef(null);
  const refNav = useRef(null);
  const burger = useSelector((state) => state.userInterfacWhitelist.burger);
  const dispatch = useDispatch();

  useEffect(() => {
    if (burger === true) {
      setRender(true);

      gsap.to(refState.current, {
        duration: 0.5,
        y: '0%',

        onComplete() {
          gsap.to(refNav.current, {
            duration: 0.25,
            display: 'block',
            autoAlpha: 1,
          });
        },
      });
    }

    if (burger === false) {
      gsap.to(refState.current, {
        duration: 0.5,
        y: '-100%',

        onComplete() {
          setRender(false);
          dispatch({ type: 'SET_MODAL_CLOSE' });
        },
      });

      gsap.to(refNav.current, {
        duration: 0.25,
        autoAlpha: 0,

        onComplete() {
          gsap.set(refNav.current, {
            display: 'none',
          });
        },
      });
    }
  }, [render, burger, dispatch]);

  const handleCloseModal = () => {
    setRender(false);
    dispatch({ type: 'SET_MODAL_CLOSE' });
    document.body.removeAttribute('style');
  };

  if (render === true) {
    return (
      <State ref={refState}>
        <Nav ref={refNav}>
          <NavLists>
            <NavItem onClick={handleCloseModal} item1>
              <ButtonBacon
                icon="casino"
                title={intl.formatMessage({ id: 'global.navigation.casinos' })}
                to="/casinos-online"
              />
            </NavItem>

            <NavItem onClick={handleCloseModal} item2>
              <ButtonBacon
                icon="slot"
                title={intl.formatMessage({ id: 'global.navigation.slots' })}
                to="/slots"
              />
            </NavItem>

            <NavItem onClick={handleCloseModal} item3>
              <ButtonBacon
                icon="sport"
                title={intl.formatMessage({ id: 'global.navigation.sports' })}
                to="/sports"
              />
            </NavItem>

            <NavItem onClick={handleCloseModal} item4>
              <ButtonBacon
                icon="esport"
                title={intl.formatMessage({ id: 'global.navigation.esports' })}
                to="/e-sports"
              />
            </NavItem>

            <NavItem onClick={handleCloseModal} item5>
              <ButtonBacon
                icon="promotion"
                title={intl.formatMessage({
                  id: 'global.navigation.promotions',
                })}
                to="/promotions"
              />
            </NavItem>

            <NavItem onClick={handleCloseModal} item6>
              <ButtonBacon
                icon="reward"
                title={intl.formatMessage({ id: 'global.navigation.reward' })}
                blank="https://www.alltoppoint.com/"
              />
            </NavItem>

            {/*
            <NavItem onClick={handleCloseModal} item7>
              <ButtonBacon
                icon="event"
                title={intl.formatMessage({ id: 'global.navigation.events' })}
                to="/"
              />
            </NavItem>
            */}

            <NavItem onClick={handleCloseModal} item8>
              <ButtonBacon
                icon="partner"
                title={intl.formatMessage({ id: 'global.navigation.partners' })}
                blank="/affiliate"
              />
            </NavItem>
          </NavLists>
        </Nav>
      </State>
    );
  }

  return null;
};

export default Bacon;
